import React from "react";

import {MainLayout} from "../components/layout"
import {InfoSection} from "../components/info";

import shared from "../styles/shared.module.styl";
import { Helmety } from "../components/Head";


export default class Contacto extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/servicios_editoriales/"
          title="Servicios editoriales - Dharma Books"
          content="Impresión y confección de libros empresariales, manuales, folletería, corrección y edición, traducciones del inglés o francés al español..."
          imgURL="https://www.dharmabooks.com.mx/static_resources/logos/griso_sl.png"
        />
        <div className={shared.container}>
          <InfoSection
            header="Servicios editoriales"
            content={(<React.Fragment><p>Dharma Books a través de Publicaciones Dharma Books & Publishing SA de CV, te ofrece los siguientes servicios editoriales:</p>
              <ul>
                <li>Impresión y confección de libros empresariales, manuales internos, folletería, libros literarios, y autopublicaciones de cualquier índole y género.</li>
                <li>Corrección y edición de tesis o trabajos académicos.</li>
                <li>Corrección y edición de textos gubernamentales (o similares).</li>
                <li>Edición de textos comerciales, literarios y empresariales.</li>
                <li>Corrección y unificación de estilo de cualquier tipo de texto.
                </li>
                <li>Traducción del inglés/francés al español. </li>
                <li>Gestión y asesoría sobre cualquier tema referente al ámbito editorial (ISBN y Registro Público)</li>

              </ul>
              </React.Fragment>)} />
        </div>
      </MainLayout>
    )
  }
}

